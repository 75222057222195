// Fields
import { SELECTION_PAGE_LIMIT } from './numericConstants';

export const REQUIRED_FIELD = 'This field is required';
export const FIELD_LENGTH_70 = 'Field must be maximum 70 characters';
export const FIELD_LENGTH_5000 = 'Field must be maximum 5000 characters';
export const FIELD_LENGTH_50 = 'Field must be maximum 50 characters';
export const FIELD_LENGTH_4 = 'Field must be maximum 4 characters';
export const FIELD_MUST_BE_UNIQUE = 'This field must be unique';
export const FIELD_IS_NOT_VALID = 'Field is not valid';
export const NUMERIC_VALUE_EXPECTED = 'Numeric value is expected';
export const SCORE_INVALID = 'Please, input valid score';
export const FIGI_INVALID = 'FIGI format is incorrect';
export const NO_DATA = 'None';
export const INVALID_DATE = 'Invalid date';

// Tooltip title
export const SORT = 'Sort';
export const EDIT = 'Edit';
export const MAJOR_DOCUMENT = 'Major document';
export const PRIVATE_DEAL = 'Private Deal';
export const PRIVATE_DOC = 'Private document';
export const DEAL_WITH_PRIVATE_DOC = 'Deal with private document';
export const UPDATE_PORTFOLIO = 'Update portfolio';
export const ADD_TO_PORTFOLIO = 'Add to portfolio';
export const DOCUMENT_NOT_PRESENTED = 'Document is not presented';
export const PENDING_USER_GENERATE_API_KEY =
  'You cannot generate API key for user with status Pending';
export const VIEWER_USER_DISABLED_TOOLTIP =
  'This content is for subscribers only. Please contact us for details';

export const COPY_MODAL_TOPICS_DESCRIPTION =
  'Select the document from which you wish to copy the topic details into this document. Any fields that are not empty will not be overwritten.';
export const COPY_MODAL_LABELS_DESCRIPTION =
  'Select the document from which you wish to copy labels into this document.';

// Email
export const EMAIL_IS_NOT_VALID = 'Email address is not valid';
export const SHARED_PORTFOLIO_USERS_LIMIT = 'Maximum amount of users that can be added is 1000';
export const EMAIL_VERIFIED = 'Successful email verification';

// User
export const USER_DISABLED = 'User is disabled.';
export const ACCOUNT_EXPIRED = 'Your account has expired.';
export const VERIFICATION_FAILED = 'Verification failed';
export const USER_EXIST = 'User with this email already exists';
export const USER_CREATED = 'User was successfully created';
export const USER_DELETED = 'User was successfully deleted';
export const USER_EDITED = 'User updates are saved';
export const USER_DELETE_MODAL_TITLE = 'Delete User';
export const USER_DELETE_MODAL_TEXT =
  'Are you sure, that you want to delete user? It cannot be undone';
export const USER_BULK_EDIT_MODAL_TITLE = 'Users Bulk Edit';
export const USER_BULK_EDITED = 'Users updates are saved';
export const USER_BULK_EDIT_MODAL_TEXT = `The changes would be applied for the following users. 
If there's no input in the field below, then no change happens:`;
export const DOWNLOAD_LIMIT_REACHED =
  'You have reached the maximum permitted downloads for a trial user';

// Label
export const LABEL_DELETE_TITLE = 'Delete Label';
export const LABEL_DELETE_MESSAGE = 'Are you sure that you want to delete this label?';

// Password
export const PASSWORD_NOT_MATCH = 'Passwords do not match';
export const PASSWORD_MUST_CONTAIN = `Your password must meet the following criteria:
- At least one uppercase letter (A-Z)
- At least one lowercase letter (a-z)
- At least one number digit (0-9)
- At least one special character
- Does not start/end with space character
- Password length must be between 8 and 20 characters.`;
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const VERIFICATION_CODE_IS_NOT_VALID =
  'Invalid verification code provided, please try again.';

// Topic
export const LIMIT_TOPIC_ANSWERS_ERROR = 'It is not allowed to add more answers';
export const UNIQUE_TOPIC_ANSWERS_ERROR = 'Answer must be unique';
export const TOPIC_CREATED = 'Topic was successfully created';
export const TOPIC_EDITED = 'Topic updates are saved';
export const TOPIC_DELETED = 'Topic was successfully deleted';
export const TOPIC_DELETE_TITLE = 'Delete Topic';
export const TOPIC_DELETE_MESSAGE =
  'Are you sure, that you want to delete topic? It cannot be undone';
export const TOPIC_NO_SELECTED_DATA_TEXT = 'Please select topic to see the answers';

// Category
export const CATEGORY_CREATED = 'Category was successfully created';
export const CATEGORY_EDITED = 'Category updates are saved';
export const CATEGORY_DELETED = 'Category was successfully deleted';
export const QUICK_REFERENCE_BOOKMARKS = 'Quick reference bookmarks';

// Post
export const POST_DELETE_TITLE = 'Delete Post';
export const POST_DELETE_MESSAGE =
  'Are you sure, that you want to delete post? It cannot be undone';
export const POST_CREATED = 'Post was successfully created';
export const POST_EDITED = 'Post updates are saved';
export const POST_DELETED = 'Post was successfully deleted';
export const POST_CHANGES_UNSAVED = 'Changes were not saved';
export const STATUS_DRAFT = 'Draft';
export const STATUS_LIVE = 'Live';

// Deal
export const CREATE_DEAL_NO_DOC = 'Create Deal with no document';
export const ADD_DEALS_TO_PORTFOLIO_MODAL_TITLE = 'Add selected Deals to the following portfolios';

// Portfolio
export const NO_PORTFOLIO =
  ' You have not created any portfolios yet. Click +New Portfolio and select Deals for your portfolios.';
export const NO_SHARED_PORTFOLIO = 'There are no portfolios shared with you';
export const SHARE_PORTFOLIO_INSTRUCTIONS =
  'Type the email addresses of the users you would like to share the portfolio with.\n' +
  'Separate different email addresses with a comma, semicolon or space';
export const DEAL_CAN_NOT_ADDED = 'Deal cannot be added to a shared portfolio';
export const ADD_DEAL = 'Add Deal';
export const SHARED_PORTFOLIO = 'Portfolios shared with me';
export const PORTFOLIO_IS_FULL = 'Portfolio cannot contain more than 200 deals';
export const SELECT_PORTFOLIO = 'Select portfolio to add deals';

// Files
export const DOWNLOAD_FILE_ERROR_MESSAGE = 'Your file can not be downloaded';
export const DOWNLOAD_FILE_SUCCESS_MESSAGE = 'Your file was successfully downloaded';
export const UPLOAD_FILE_SUCCESS_MESSAGE = 'Your file(s) was successfully uploaded';
export const UPLOAD_FILE_ERROR_MESSAGE = 'Your file(s) can not be uploaded';
export const UPLOAD_FILE_VALIDATE_SIZE_MESSAGE = 'File should not be bigger than 12 MBs';
export const UPLOAD_FILE_VALIDATE_TYPE_MESSAGE = 'Please upload file having extension .xlsx only.';
export const UPLOAD_ONLY_ONE_FILE_VALIDATE_MESSAGE = 'Only 1 file could be selected for upload';
export const UPLOAD_FILE_VALIDATE_SIZE_10MB_MESSAGE = 'File size should not be greater than 10 MB';

// PDF Viewer
export const NO_MORE_5000_CHARACTERS =
  'More than 5000 characters were selected. Please, select less characters to add the label.';
export const SELECTION_PAGES_OVER_LIMITED = `Data from more than ${SELECTION_PAGE_LIMIT} pages were selected. Please, select less pages of data to perform action.`;
export const NO_SELECTION_PDF_DOC = 'Select text in PDF document.';
export const SELECTED_TEXT_OUT_OF_PDF_DOC =
  'Selected text is out of PDF document. Please select text inside document.';
export const ELEMENT_ADDED = 'Element was successfully added.';
export const ELEMENT_DELETED = 'Element was successfully deleted';
export const ELEMENT_EDITED = 'Element was successfully edited';
export const STATUS_CANNOT_CHANGED_EXPIRED =
  'Deal Status cannot be changed from "Private" to "Expired';
export const STATUS_CANNOT_CHANGED_BENCHMARK =
  'Deal Status cannot be changed from "Private" to "Benchmark';
export const STATUS_CANNOT_CHANGED_ACTIVE = 'Document Status cannot be changed to "Not present"';
export const FIGI_ALREADY_EXIST = 'This FIGI already exist';
export const WORKFLOW_STATUS_CANNOT_CHANGED_WITHOUT_TIME =
  'Please, estimate your efforts in the field "Time Taken"';
export const WORKFLOW_ONLY_ONE_INPUTTER = 'There can be no more than 1 review with type "Inputter"';
export const WORKFLOW_STATUS_CANNOT_CHANGED_WITHOUT_NAME =
  'Please, set the value in the field "Name"';
export const INPUT_WAS_NOT_SAVED = 'Your input was not saved';
export const EXTERNAL_LABEL_DOCS_TITLE = 'Add label to the following documents:';
export const INTEGER_REQUIRED = 'should be an integer';
export const REPORT_NOT_FOUND = 'Report not found';
export const DOCUMENT_WORKFLOW_DOES_NOT_EXIST = 'Document workflow does not exist';

// Common
export const REQUEST_RECEIVED = 'Your request has been received';
export const PAGE_NOT_EXIST = 'The page does not exist. Please, reload the page.';
export const NO_RECORDS_TO_DISPLAY = 'No records to display';
export const NO_OPTIONS = 'No options';
export const OPTIONS_LOADING = 'Options are loading!';

export const EMPTY_VALUE = 'Empty';

// Report
export const YOU_SURE_TO_DELETE =
  'Are you sure, that you want to delete report? It cannot be undone';
export const REPORT_SUCCESSFULLY_DELETED = 'Report was successfully deleted';
export const REPORT_SUCCESSFULLY_CREATED = 'Report was successfully created';
export const REPORT_SUCCESSFULLY_UPDATED = 'The changes are successfully saved';
export const REPORT_CHANGES_UNSAVED = 'Changes were not saved';
export const REPORT_UNSUCCESSFULLY_UPDATED =
  'Changes are not saved. Please, try to repeat your action';

// Pagination
export const PAGE_FIRST = 'Must be greater than 1 or equal to 1';

// Tabs Search Input
export const ADMIN_TOPICS_SEARCH = 'Search Topic';
export const DEAL_SEARCH = 'Search Deal Name, FIGI, ticker, CUSIP or ISIN';
export const REPORTS_SEARCH = 'Search Report';
export const ADMIN_PRIVATE_CONTENT_SEARCH = 'Search Deal, Document or User';

// Scores
export const D_SCORE =
  'Overall score for manager flexibility on a scale of 0 (most restrictive) to 5 (most flexible)';
export const ESG_SCORE =
  'Strength of environmental, social and governance rules in this deal, where higher is stronger';
export const CATEGORY_SCORE =
  'Category score for manager flexibility on a scale of 0 (most restrictive) to 5 (most flexible)';
export const TOPIC_SCORE =
  'Topic score for manager flexibility on a scale of 0 (most restrictive) to 5 (most flexible)';

// HomePage
export const FILL_ALL_FIELDS = 'Please, fill in all mandatory fields';

// About Us
export const LONDON_ADDRESS = '3 Waterhouse Square, 138 Holborn, London EC1N 2SW';
export const LONDON_CONTACT = '+44 20 45388904';
export const NEWYORK_ADDRESS = '1178 Broadway, 3rd Floor #3761, New York, NY 10001';
export const NEWYORK_CONTACT = '+1 332 3319821';

// Deal Type
export const DEAL_TYPE_CREATED = 'Deal type was successfully created';
export const DEAL_TYPE_EDITED = 'Deal type was successfully edited';
export const DEAL_TYPE_DELETED = 'Deal type was successfully deleted';

// Product
export const PRODUCT_CREATED = 'Product was successfully created';
export const PRODUCT_EDITED = 'Product updates are saved';
export const PRODUCT_DELETED = 'Product was successfully deleted';

// API Methods
export const API_PUT = 'put';
export const API_POST = 'post';
export const API_DELETE = 'delete';

// Actions
export const ACTION_CREATE = 'create';
export const ACTION_EDIT = 'edit';

// Deal Statuses
export const STATUS_PRIVATE = 'Private';

// User Statuses
export const USER_STATUS_PENDING = 'Pending';

// Versions of topics
export const SINGLE_VERSION = 'singleVer';
export const MULTIPLE_VERSION = 'multiVer';

// Objects visibility types
export const OBJECT_VISIBLE = 'Visible';
export const OBJECT_NOT_VISIBLE = 'Not visible';

// User roles
export const ADMIN_ROLE = 'Admins';
export const EDITOR_ROLE = 'Editors';
export const USER_ROLE = 'Users';
export const TRIAL_USER_ROLE = 'TrialUsers';
export const VIEWER_USER_ROLE = 'ViewerUsers';

// Errors
export const NETWORK_ERROR = 'Network error';

// Employee
export const EMPLOYEE_DELETE_TITLE = 'Delete Post';
export const EMPLOYEE_DELETE_MESSAGE =
  'Are you sure, that you want to delete Employee? It cannot be undone';
export const EMPLOYEE_EDITED = 'Employee updates are saved';
export const EMPLOYEE_DELETED = 'Employee was successfully deleted';

// Events
export const PAGE_ACCESS_EVENT = 'Page access';
export const LOGIN_EVENT = 'Sign in';
export const LOGOUT_EVENT = 'Sign out';
export const EXPORT_EVENT = 'Export';
export const TAB_ACCESS_EVENT = 'Tab access';

// Review types
export const INPUTTER_REVIEW_TYPE = 'Inputter';
export const EDITOR_REVIEW_TYPE = 'Editor';
export const FINAL_CHECKER_REVIEW_TYPE = 'Final checker';

// Sign Up
export const VERIFICATION_FORM = 'sendVerificationCode';
export const RESET_PASSWORD_FORM = 'resetPasswordForm';
