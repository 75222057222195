import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import useStyles from './styles';
import ContactEmailItem from './ContactEmailItem/ContactEmailItem';

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.wrapper}>
      <Grid item>
        <Grid container className={classes.adressWrapper}>
          <Grid item className={classes.adressBlock}>
            <Typography variant="h4" className={classes.city}>
              New York
              <br />
            </Typography>
            <Typography variant="h4" className={classes.country}>
              United States
            </Typography>
            <Typography variant="body" className={classes.adress}>
              1178 Broadway, 3rd Floor #3761, New York, NY 10001
              <br />
              +1 332 3319821
              <br />
              <ContactEmailItem classes={classes} />
            </Typography>
          </Grid>
          <Grid item className={classes.adressBlock}>
            <Typography variant="h4" className={classes.city}>
              London
              <br />
            </Typography>
            <Typography variant="h4" className={classes.country}>
              United Kingdom
            </Typography>
            <Typography variant="body" className={classes.adress}>
              3 Waterhouse Square, 138 Holborn, London EC1N 2SW
              <br />
              +44 20 45388904
              <br />
              <ContactEmailItem classes={classes} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <span className={classes.divider} />
        <Grid className={classes.linksWrapper}>
          <Typography className={classes.copyright} variant="body">
            © 2024 Dealscribe Limited
          </Typography>
          <Grid className={classes.termsContainer}>
            <Grid className={classes.termsAndAbout}>
              <Typography variant="body">
                <Link
                  target="_blank"
                  className={classes.terms}
                  href={`${process.env.PUBLIC_URL}/T&C and GDPR.pdf`}
                >
                  Terms and conditions
                </Link>
              </Typography>
              <Typography variant="body">
                <Link className={classes.terms} href="/about-us">
                  About us
                </Link>
              </Typography>
            </Grid>

            <Grid item>
              <Link target="_blank" href="https://www.linkedin.com/company/dealscribe">
                <LinkedInIcon fontSize="large" className={classes.linkedInIcon} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
