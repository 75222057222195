import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Button, Grid, Toolbar, Tooltip, CircularProgress } from '@mui/material';
import AddTopicModal from 'src/MainLayout/OperationsPage/TopicManagement/AddTopicModal';
import AddUserModal from 'src/MainLayout/OperationsPage/UserManagement/AddUserModal';
import UnsavedMessageModal from 'src/components/UnsavedMessageModal';
import Tabs from 'src/components/Tabs';
import { showAlert } from 'src/redux/actions';
import * as constants from 'src/utils/constants/stringConstants';
import { downloadRetryLimit, downloadRetryLimit15 } from 'src/utils/constants/numericConstants';
import {
  getTopic,
  getTopicExport,
  postTopicCreateNew,
  postTopicImport
} from 'src/services/api/topic';
import { getUserExportWithFilters, postUser, postUserCreateNew } from 'src/services/api/user';
import { postCategory } from 'src/services/api/category';
import { alertBody } from 'src/MainLayout/DealPage/utils';
import {
  ADMIN_ROLE,
  EDITOR_ROLE,
  TRIAL_USER_ROLE,
  USER_ROLE
} from 'src/utils/constants/stringConstants';
import SearchTopicModal from '../TopicManagement/SearchTopicModal/SearchTopicModal';
import { TabsSearchBar } from '../../../components/TabsSearchBar';
import * as actionsCreator from '../context/operations.actions';
import operationsContext from '../context/operations.context';
import useStyles from './styles';
import { postDealType } from '../../../services/api/dealType';
import AddEditDealTypeModal from '../DealTypeManagement/components/AddEditDealTypeModal';
import { postProduct } from '../../../services/api/product';
import AddEditProductModal from '../ProductManagement/components/AddEditProductModal';
import { getGroupedByProductDealTypes } from '../DealTypeManagement/utils';
import AddEditCategoryModal from '../CategoryManagement/components/AddEditCategoryModal';
import { processRequestState } from '../utils';
import { areStringsEqualIgnoreCase } from '../../../utils/common';
import { isManagingUser } from '../../../utils/userRole';

function OperationsPageHeader({ tabs }) {
  const {
    state: {
      downloadUsers,
      downloadTopics,
      importTopics,
      topics,
      users,
      categories,
      privateContent,
      groupedByProductDealTypes,
      products
    },
    dispatch
  } = useContext(operationsContext);
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const userRole = useSelector((state) => state.session.user.role);

  const defaultAdminTab = '/operations/topic-management';
  const defaultEditorTab = '/operations/workflow';

  const [openAddNewTopic, handleAddNewTopic] = useState(false);
  const [openModalConfirmUnsaved, setOpenModalConfirmUnsaved] = useState(false);
  const [openAddNewUser, setAddNewUser] = useState(false);
  const [openAddNewCategoryModal, setOpenAddNewCategoryModal] = useState(false);
  const [openAddNewDealTypeModal, setOpenAddNewDealTypeModal] = useState(false);
  const [openAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [openSearchTopic, setOpenSearchTopic] = useState(false);
  const [currentTab, setCurrentTab] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleChangeTopicSearch = (value) => {
    dispatch(actionsCreator.searchTopics(value));
  };

  const handleChangePrivateContentSearch = (value) => {
    dispatch(actionsCreator.searchPrivateContent(value));
  };

  const { pathname } = window.location;

  useEffect(() => {
    if (userRole === ADMIN_ROLE) {
      navigate(defaultAdminTab);
      setCurrentTab(pathname);
    }

    if (userRole === EDITOR_ROLE) {
      navigate(defaultEditorTab);
      setCurrentTab(pathname);
    }

    if ((userRole && userRole === USER_ROLE) || userRole === TRIAL_USER_ROLE) {
      navigate('/errors/error-403');
    }
    setCurrentTab(pathname);
  }, []);

  useEffect(() => {
    if (isManagingUser(userRole)) {
      setCurrentTab(pathname);
    }

    if ((userRole && userRole === USER_ROLE) || userRole === TRIAL_USER_ROLE) {
      navigate('/errors/error-403');
    }
    setCurrentTab(pathname);
  }, [pathname, navigate, userRole]);

  const createNewTopic = async (params) => {
    try {
      await postTopicCreateNew(params);
      const data = await getTopic();
      dispatch(actionsCreator.loadTopicsSuccess(data));
      dispatchRedux(
        showAlert({ isShown: true, type: 'success', message: constants.TOPIC_CREATED })
      );
    } catch (error) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data?.message || error.message
        })
      );
    }
  };

  const checkUniqueTopicName = useCallback(
    (name) => !topics.data.some((item) => item.topicName === name),
    [topics]
  );

  const checkUniqueUserEmail = useCallback(
    (email) => !users.data.users.some((item) => areStringsEqualIgnoreCase(item.email, email)),
    [users]
  );

  const checkUniqueCategoryName = (name) =>
    !categories.data.some((category) => category.name === name);

  const checkUniqueDealTypeName = (name) =>
    !groupedByProductDealTypes.data.some((productDealTypes) =>
      productDealTypes.dealTypes.some((dealType) => dealType.name === name)
    );

  const checkUniqueProductName = (name) => !products.data.some((product) => product.name === name);

  const getUsers = async () => {
    try {
      dispatch(actionsCreator.loadUsers());
      const data = await postUser();
      dispatch(actionsCreator.loadUsersSuccess(data));
      dispatch(actionsCreator.updateUsersAvailableFilters(data.filter));
    } catch (error) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data.message || error.message
        })
      );
    }
  };

  const createNewUser = async (params) => {
    dispatch(actionsCreator.filterUserReset());
    try {
      await postUserCreateNew(params);
      dispatchRedux(showAlert({ isShown: true, type: 'success', message: constants.USER_CREATED }));
      await getUsers();
    } catch (error) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data?.message || error.message
        })
      );
    }
  };

  const createNewCategory = async (params) => {
    try {
      setOpenAddNewCategoryModal(false);
      const data = await postCategory(params);
      dispatch(actionsCreator.loadCategoriesSuccess(data));
      dispatchRedux(
        showAlert({ isShown: true, type: 'success', message: constants.CATEGORY_CREATED })
      );
    } catch (error) {
      setOpenAddNewCategoryModal(false);
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data?.message || error.message
        })
      );
    }
  };

  const createNewDealType = async ({ product, name }) => {
    try {
      setOpenAddNewDealTypeModal(false);
      await postDealType(product.id, name);
      getGroupedByProductDealTypes(dispatch, dispatchRedux);
      dispatchRedux(
        showAlert({ isShown: true, type: 'success', message: constants.DEAL_TYPE_CREATED })
      );
    } catch (error) {
      setOpenAddNewDealTypeModal(false);
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data?.message || error.message
        })
      );
    }
  };

  const createNewProduct = async (params) => {
    try {
      setOpenAddNewProductModal(false);
      const data = await postProduct(params);
      dispatch(actionsCreator.loadProductsSuccess(data));
      dispatchRedux(
        showAlert({ isShown: true, type: 'success', message: constants.PRODUCT_CREATED })
      );
    } catch (error) {
      setOpenAddNewProductModal(false);
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: error?.response?.data?.message || error.message
        })
      );
    }
  };

  const handleClickDownloadAllUsers = async () => {
    dispatch(actionsCreator.downloadUsers(true));
    const requestId = uuid();
    const body = { ...users.data.filter };
    try {
      let requestState = { url: null, count: 0 };
      do {
        // eslint-disable-next-line no-await-in-loop
        const { url } = await getUserExportWithFilters(body, requestId);
        requestState = { url, count: requestState.count + 1 };
      } while (requestState.count < downloadRetryLimit && !requestState.url);
      dispatch(actionsCreator.downloadUsers(false));
      processRequestState(dispatchRedux, requestState);
    } catch (err) {
      dispatch(actionsCreator.downloadUsers(false));
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err?.message
        })
      );
    }
  };

  const downloadTopicFile = async (id) => {
    dispatch(actionsCreator.downloadTopics(true));
    const requestId = uuid();
    const params = { ...(!!id && { topicId: id }), requestId };
    try {
      let requestState = { url: null, count: 0 };
      do {
        // eslint-disable-next-line no-await-in-loop
        const { url } = await getTopicExport(params);
        requestState = { url, count: requestState.count + 1 };
      } while (requestState.count < downloadRetryLimit && !requestState.url);
      dispatch(actionsCreator.downloadTopics(false));
      processRequestState(dispatchRedux, requestState);
    } catch (err) {
      dispatch(actionsCreator.downloadTopics(false));
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err.message
        })
      );
    }
  };

  const downloadTopicAction = () => {
    setOpenSearchTopic(true);
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const validateSize = (file) => {
    const FileSize = file.size / 1024 / 1024; // in MiB
    if (FileSize > 12) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: constants.UPLOAD_FILE_VALIDATE_SIZE_MESSAGE
        })
      );
      return false;
    }
    return true;
  };

  const validateType = (file) => {
    const filePath = file.name;
    const allowedExtensions = /(\.xlsx)$/i;
    if (!allowedExtensions.exec(filePath)) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: constants.UPLOAD_FILE_VALIDATE_TYPE_MESSAGE
        })
      );
      return false;
    }
    return true;
  };

  const importFile = async (file) => {
    const validSize = validateSize(file);
    const validType = validateType(file);
    if (validSize && validType) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      dispatch(actionsCreator.importTopics(true));
      const requestId = uuid();
      try {
        let requestState = { status: null, message: null, count: 0 };
        do {
          // eslint-disable-next-line no-await-in-loop
          const { status, message } = await postTopicImport(formData, requestId);
          requestState = { status, count: requestState.count + 1, message };
        } while (requestState.status === 408 && requestState.count < downloadRetryLimit15);
        dispatch(actionsCreator.importTopics(false));

        dispatchRedux(
          showAlert(
            alertBody(
              requestState,
              constants.UPLOAD_FILE_SUCCESS_MESSAGE,
              constants.UPLOAD_FILE_ERROR_MESSAGE
            )
          )
        );
      } catch (err) {
        dispatchRedux(
          showAlert({
            isShown: true,
            type: 'error',
            message: err?.response?.data?.message || err?.message
          })
        );
      }
    }
  };

  const onFile = async () => {
    const fileInput = document.querySelector('input[type=file]');
    const file = fileInput.files[0];

    try {
      await importFile(file);
    } catch (err) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err?.message
        })
      );
    }
  };

  const closeUnsavedTopicData = () => {
    setOpenModalConfirmUnsaved(false);
    handleAddNewTopic(false);
  };

  const closeUnsavedUserData = () => {
    setOpenModalConfirmUnsaved(false);
    setAddNewUser(false);
  };

  const closeUnsavedCategoryData = () => {
    setOpenModalConfirmUnsaved(false);
    setOpenAddNewCategoryModal(false);
  };

  const closeUnsavedDealTypeData = () => {
    setOpenModalConfirmUnsaved(false);
    setOpenAddNewDealTypeModal(false);
  };

  const closeUnsavedProductData = () => {
    setOpenModalConfirmUnsaved(false);
    setOpenAddNewProductModal(false);
  };

  const handleCloseSearchTopic = () => {
    setOpenSearchTopic(false);
  };

  const adminTopicManagementHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedTopicData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <TabsSearchBar
        placeholder={constants.ADMIN_TOPICS_SEARCH}
        handleSubmit={handleChangeTopicSearch}
        searchValueState={topics.search || ''}
        style={{ width: '148px' }}
      />
      <input
        key={uuid()}
        hidden
        name="file"
        type="file"
        ref={fileInputRef}
        accept=".xlsx"
        onChange={onFile}
      />
      <Grid>
        <Tooltip disableInteractive title="Import allows to update the answers and notes only">
          <Button
            className={classes.button}
            size="medium"
            color="black"
            variant="outlined"
            onClick={handleAttach}
            disabled={importTopics}
            endIcon={importTopics && <CircularProgress size={14} color="secondary" />}
          >
            IMPORT
          </Button>
        </Tooltip>
        <Button
          aria-controls="download-menu"
          aria-haspopup="true"
          variant="outlined"
          color="black"
          className={classes.button}
          onClick={downloadTopicAction}
          disabled={downloadTopics}
          endIcon={downloadTopics && <CircularProgress size={14} color="secondary" />}
        >
          Download
        </Button>
      </Grid>
      <Grid item>
        <AddTopicModal
          open={openAddNewTopic}
          onClose={(isChanged) =>
            isChanged ? setOpenModalConfirmUnsaved(true) : handleAddNewTopic(false)
          }
          submit={createNewTopic}
          checkUniqueTopicName={checkUniqueTopicName}
        />
        <Button
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => handleAddNewTopic(true)}
        >
          ADD TOPIC
        </Button>
      </Grid>
      <Grid item>
        <SearchTopicModal
          open={openSearchTopic}
          onClose={handleCloseSearchTopic}
          topics={topics.data}
          downloadTopic={(id) => downloadTopicFile(id)}
        />
      </Grid>
    </Grid>
  );

  const adminEditorUserManagementHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>
        <p>Total users: {users.totalNumbers}</p>
      </Grid>
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedUserData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <Grid item>
        <Button
          aria-controls="download-menu"
          aria-haspopup="true"
          variant="outlined"
          color="black"
          className={classes.button}
          onClick={() => handleClickDownloadAllUsers()}
          disabled={downloadUsers}
          endIcon={downloadUsers && <CircularProgress size={14} color="secondary" />}
        >
          Download
        </Button>
        <AddUserModal
          open={openAddNewUser}
          onClose={(isChanged) =>
            isChanged ? setOpenModalConfirmUnsaved(true) : setAddNewUser(false)
          }
          createNewUser={createNewUser}
          checkUniqueUserEmail={checkUniqueUserEmail}
          allProducts={products.data}
          userRole={userRole}
        />
        <Button
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => setAddNewUser(true)}
        >
          ADD USER
        </Button>
      </Grid>
    </Grid>
  );

  const adminCategoryManagementHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedCategoryData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <Grid item>
        <AddEditCategoryModal
          products={products.data}
          open={openAddNewCategoryModal}
          onClose={(isChanged) =>
            isChanged ? setOpenModalConfirmUnsaved(true) : setOpenAddNewCategoryModal(false)
          }
          submit={createNewCategory}
          checkUniqueCategoryName={checkUniqueCategoryName}
          header="Add New Category"
        />
        <Button
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => setOpenAddNewCategoryModal(true)}
        >
          ADD CATEGORY
        </Button>
      </Grid>
    </Grid>
  );

  const adminDealTypeManagementHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedDealTypeData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <Grid item>
        <AddEditDealTypeModal
          open={openAddNewDealTypeModal}
          onClose={(isChanged) =>
            isChanged ? setOpenModalConfirmUnsaved(true) : setOpenAddNewDealTypeModal(false)
          }
          submit={createNewDealType}
          checkUniqueDealTypeName={checkUniqueDealTypeName}
          products={products.data}
          header="Add Deal Type"
        />
        <Button
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => setOpenAddNewDealTypeModal(true)}
        >
          ADD DEAL TYPE
        </Button>
      </Grid>
    </Grid>
  );

  const adminProductManagementHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedProductData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <Grid item>
        <AddEditProductModal
          open={openAddNewProductModal}
          onClose={(isChanged) =>
            isChanged ? setOpenModalConfirmUnsaved(true) : setOpenAddNewProductModal(false)
          }
          submit={createNewProduct}
          checkUniqueProductName={checkUniqueProductName}
          header="Add Product"
        />
        <Button
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => setOpenAddNewProductModal(true)}
        >
          ADD PRODUCT
        </Button>
      </Grid>
    </Grid>
  );

  const adminPrivateContentHeader = (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid>
        <TabsSearchBar
          disabled={privateContent.loading || users.loading}
          placeholder={constants.ADMIN_PRIVATE_CONTENT_SEARCH}
          handleSubmit={handleChangePrivateContentSearch}
          searchValueState={privateContent.search || ''}
          style={{ width: '290px' }}
        />
      </Grid>
    </Grid>
  );

  const adminHeader = (() => {
    switch (currentTab) {
      case '/operations/topic-management':
        return adminTopicManagementHeader;
      case '/operations/user-management':
        return adminEditorUserManagementHeader;
      case '/operations/category-management':
        return adminCategoryManagementHeader;
      case '/operations/deal-types':
        return adminDealTypeManagementHeader;
      case '/operations/products':
        return adminProductManagementHeader;
      case '/operations/private-content':
        return adminPrivateContentHeader;
      case '/operations/workflow':
        return null;
      case '/operations/analytics':
        return null;
      default:
        return adminTopicManagementHeader;
    }
  })();

  const editorHeader = (() => {
    switch (currentTab) {
      case '/operations/user-management':
        return adminEditorUserManagementHeader;
      case '/operations/workflow':
        return null;
      default:
        return null;
    }
  })();

  const getHeader = () => {
    if (userRole === ADMIN_ROLE) {
      return adminHeader;
    }
    if (userRole === EDITOR_ROLE) {
      return editorHeader;
    }
    return null;
  };

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tabs
            containerStyle={classes.container}
            tabs={tabs}
            operationsNormalStyle={classes.tab}
            operationsActiveStyle={classes.active}
            operationsPage="true"
          />
        </Grid>
        <Grid item>{getHeader()}</Grid>
      </Grid>
    </Toolbar>
  );
}

OperationsPageHeader.propTypes = {
  tabs: PropTypes.array
};

export default OperationsPageHeader;
