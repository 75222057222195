import {
  ADMIN_ROLE,
  EDITOR_ROLE,
  OBJECT_NOT_VISIBLE,
  OBJECT_VISIBLE,
  TRIAL_USER_ROLE,
  USER_ROLE,
  VIEWER_USER_ROLE,
  INPUTTER_REVIEW_TYPE,
  EDITOR_REVIEW_TYPE,
  FINAL_CHECKER_REVIEW_TYPE
} from './stringConstants';
import {
  UPLOAD_DOCUMENT_TYPE_MAJOR,
  UPLOAD_DOCUMENT_TYPE_MINOR
} from '../../MainLayout/DealPage/constants';

export const dealStatusList = [
  'Active',
  'Benchmark',
  'Draft',
  'Expired',
  'Inactive',
  'Private',
  'Not present'
].sort();

export const postTypeList = [
  'Market standards report',
  'Manager report',
  'Deal update',
  'Topic report',
  'Secondary',
  'Scorecard',
  'Fiery find',
  'About us',
  'Primary',
  'Other'
];

export const userDealStatusList = ['Active', 'Benchmark', 'Expired', 'Private'].sort();

export const viewerDealStatusList = ['Active', 'Benchmark', 'Expired'].sort();

export const dealDocumentStatusList = [
  'Active',
  'Draft',
  'Inactive',
  'Private',
  'Not present'
].sort();

export const dealTypeListDefaultAnswer = ['BSL', 'CBO', 'MM'].sort();

export const topicStatusList = ['Active', 'Inactive', 'Pending'].sort();

export const currency = ['USD', 'EUR', 'GBP'];

export const workFlowReviewTypeList = [
  INPUTTER_REVIEW_TYPE,
  EDITOR_REVIEW_TYPE,
  FINAL_CHECKER_REVIEW_TYPE
];
export const workFlowReviewStatusList = ['In progress', 'Finished'];

export const userRoles = [
  {
    name: 'Admins',
    value: ADMIN_ROLE
  },
  {
    name: 'Editors',
    value: EDITOR_ROLE
  },
  {
    name: 'Users',
    value: USER_ROLE
  },
  {
    name: 'Trial Users',
    value: TRIAL_USER_ROLE
  },
  {
    name: 'Viewer Users',
    value: VIEWER_USER_ROLE
  }
];

export const commonUserRoles = [
  {
    name: 'Users',
    value: USER_ROLE
  },
  {
    name: 'Trial Users',
    value: TRIAL_USER_ROLE
  },
  {
    name: 'Viewer Users',
    value: VIEWER_USER_ROLE
  }
];

export const availableRolesForPrivateDeal = [USER_ROLE, TRIAL_USER_ROLE];

export const reportsTypeList = [
  'Scorecard',
  'Topic report',
  'Market standards report',
  'Manager report',
  'Other'
];

export const visibilityTypeList = [OBJECT_VISIBLE, OBJECT_NOT_VISIBLE];

export const documentTypeList = [UPLOAD_DOCUMENT_TYPE_MAJOR, UPLOAD_DOCUMENT_TYPE_MINOR];

export const tocSectionType = ['Article', 'Section'];

export const listOfCommonFilterOperators = [
  {
    label: 'contains',
    value: 'contains',
    requiresFilterValue: true
  },
  {
    label: 'equals',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is any of',
    value: 'isAnyOf',
    requiresFilterValue: true
  },
  {
    label: 'is not any of',
    value: 'isNotAnyOf',
    requiresFilterValue: true
  }
];

export const listOfDateFilterOperators = [
  {
    label: 'equals',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: 'is on or after',
    value: 'isOnOrAfter',
    requiresFilterValue: true
  },
  {
    label: 'is on or before',
    value: 'isOnOrBefore',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  }
];

export const listOfNumbersFilterOperators = [
  {
    label: '=',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: '>=',
    value: 'gte',
    requiresFilterValue: true
  },
  {
    label: '<=',
    value: 'lte',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  }
];

export const listOfIsMajorFilterOperators = [
  {
    label: 'is',
    value: 'is',
    requiresFilterValue: true
  }
];

export const listOfSingleSelectFilterOperators = [
  {
    label: 'is',
    value: 'is',
    requiresFilterValue: true
  },
  {
    label: 'is not',
    value: 'not',
    requiresFilterValue: true
  },
  {
    label: 'is any of',
    value: 'isAnyOf',
    requiresFilterValue: true
  }
];

export const DEFAULT_SORTING_ORDER = ['asc', 'desc'];
